import { useMsal } from "@azure/msal-react"
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from "authHelper"
import axios from "axios"
import { ErrorMessage, Field, Formik } from "formik"
import _ from "lodash"
import { useEffect, useState } from "react"
import BForm from 'react-bootstrap/Form'
import { Button, Col, Container, Form, Row, Spinner, Toast, ToastContainer } from "react-bootstrap"
import { useParams } from "react-router-dom"
import * as Yup from 'yup'

const OnboardMigratedPracticeDetails = () => {
    const [practice, setPractice] = useState({})
    const [showToastMsg, setShowToastMsg] = useState({ visible: false, msg: '' })
    const { instance, accounts } = useMsal()
    const params = useParams()
    const [submit, setSubmit] = useState({})
    const mapToPracticeDetails = (data) => {
        return {
            id: data.id,
            practiceName: _.get(data, 'name'),
            abbreviation: _.get(data, 'abbreviation'),
            practiceSoftwareName: _.get(data, 'software.name'),
            postCodeId: _.get(data, 'postcode.code'),
            practiceGroupName: _.get(data, 'group.name'),
            phnName: _.get(data, 'phn.name'),
            pipQIStatusName: _.get(data, 'pipqiStatus.description'),
            managerEmail: _.get(data, 'managerEmail'),
            itProviderEmail: _.get(data, 'itProviderEmail'),
            appClientId: _.get(data, 'appClientId'),
        }
    }

    const validationSchema = Yup.object().shape({
        managerEmail: Yup.string()
            .nullable()
            .required('Manager Email is required')
            .email('Manager Email is not valid'),
        itProviderEmail: Yup.string()
            .nullable()
            .required('IT Provider Email is required')
            .email('IT Provider Email is not valid')
    })

    async function fetchData() {
        const authHeader = await getAuthHeader(instance, accounts)
        const httpConfig = {
            headers: authHeader
        }

        const result = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/onboard/${params.practiceId}`, httpConfig)
        const practiceDetails = mapToPracticeDetails(result.data)

        setPractice(practiceDetails)
    }

    useEffect(() => {
        fetchData()

        return () => {
            // Anything in here is fired on component unmount.
            //clearInterval(intervalId)
        }
    }, [submit])

    return (
        <>
            <ToastContainer className="p-3" position="bottom-center">
                <Toast bg="success" onClose={() => setShowToastMsg({ visible: false, msg: '' })} show={showToastMsg.visible} delay={5000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Primary Sense</strong>
                    </Toast.Header>
                    <Toast.Body>{showToastMsg.msg}</Toast.Body>
                </Toast>
            </ToastContainer>

            {!practice.id && <Spinner animation="border" variant="secondary" />}
            {practice.id &&
                <>
                    <Formik
                        enableReinitialize={true}
                        initialValues={practice}
                        validationSchema={validationSchema}
                        onSubmit={async (values, actions) => {
                            const authHeader = await getAuthHeader(instance, accounts)
                            await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/migrated/${params.practiceId}/save`, values, { headers: authHeader })
                            setShowToastMsg({ visible: true, msg: 'Welcome email with installation details will be sent to IT provider shortly' })
                            //refresh
                            setSubmit(true)
                        }}
                    >
                        {({ handleSubmit, errors, touched, isSubmitting, isValidating, dirty, resetForm }) => {
                            return (
                                <Container>

                                    <Form>
                                        <Row className="mb-3">
                                            <Col xs={8}>
                                                <BForm.Group>
                                                    <BForm.Label>Practice Name</BForm.Label>
                                                    <Field type="text" name="practiceName" disabled className={'form-control'} />
                                                    <ErrorMessage name="practiceName" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <BForm.Group>
                                                    <BForm.Label>Abbreviation</BForm.Label>
                                                    <Field type="text" name="abbreviation" disabled className={'form-control'} />
                                                    <ErrorMessage name="abbreviation" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <BForm.Group controlId="practiceSoftwareName">
                                                    <BForm.Label>Practice Software</BForm.Label>
                                                    <Field type="text" name="practiceSoftwareName" disabled className={'form-control'} />
                                                    <ErrorMessage name="practiceSoftwareName" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                            <Col>
                                                <BForm.Group controlId="pipQIStatusName">
                                                    <BForm.Label>PIP QI Status</BForm.Label>
                                                    <Field type="text" name="pipQIStatusName" disabled className={'form-control'} />
                                                    <ErrorMessage name="pipQIStatusName" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                            <Col>
                                                <BForm.Group controlId="postCodeId">
                                                    <BForm.Label>Post Code</BForm.Label>
                                                    <Field name="postCodeId" type="number" disabled className={'form-control'}>
                                                    </Field>
                                                    <ErrorMessage name="postCodeId" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>

                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <BForm.Group>
                                                    <BForm.Label>Practice Group</BForm.Label>
                                                    <Field name="practiceGroupName" disabled className={'form-control'}>
                                                    </Field>
                                                    <ErrorMessage name="practiceGroupName" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                            <Col>
                                                <BForm.Group>
                                                    <BForm.Label>Manager Email</BForm.Label>
                                                    <Field type="text" name="managerEmail" className="form-control" disabled={isSubmitting || practice.appClientId} />
                                                    <ErrorMessage name="managerEmail" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                            <Col>
                                                <BForm.Group>
                                                    <BForm.Label>IT Provider Email</BForm.Label>
                                                    <Field type="text" name="itProviderEmail" className="form-control" disabled={isSubmitting || practice.appClientId} />
                                                    <ErrorMessage name="itProviderEmail" component="div" className="invalid-feedback d-block" />
                                                </BForm.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button onClick={() => handleSubmit()} variant="outline-primary" disabled={isSubmitting || practice.appClientId}>
                                                    Start Onboarding {isSubmitting && <span className="spinner-border spinner-border-sm ml-2"></span>}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Container>
                            )
                        }}
                    </Formik>
                </>
            }
        </>
    )
}
export default OnboardMigratedPracticeDetails