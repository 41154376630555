import { useMsal } from '@azure/msal-react'
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from 'authHelper'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Card, Col, Container, Row, Spinner, Tab, Table, Tabs, Toast, ToastContainer } from 'react-bootstrap'
import _ from 'lodash'
import PracticeSettings from './PracticeSettings'
import moment from 'moment'
import { IoClipboardOutline } from 'react-icons/io5'
import AuthContext from 'store/auth-context'
import { ConfirmDialog } from 'components/ConfirmDialog'

const Practice = () => {
    const [practice, setPractice] = useState({})
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [isSendingRemindEmail, setIsSendingRemindEmail] = useState(false)
    const [showToastMsg, setShowToastMsg] = useState({ visible: false, msg: '' })
    const [extractionDetails, setExtractionDetails] = useState({})
    const [refData, setRefData] = useState({})
    const [pipqiStatus, setPipQIStatus] = useState({})
    const [showConfirmReissueIdSecretDialog, setShowConfirmReissueIdSecretDialog] = useState(false)
    const ctx = useContext(AuthContext)
    const { instance, accounts } = useMsal()
    const params = useParams()

    const onPIPQUIStatusChangeHandler = (event) => {
        setPipQIStatus(event.target.value);
    }
    const EXTRACTION_TABLE = [
        'Patient',
        'Staff',
        'PathologyRequest',
        'PatientVisit',
        'AllergicReaction',
        'ClinicalHistory',
        'Consultation',
        'Document',
        'Immunisation',
        'MBSBilledRecord',
        'Medication',
        'Observation',
        'PathologyResult',
        'PatientLifestyle',
        'Pregnancy',
        'Prescription',
        'VisitReason',
        'AppointmentDate',
        'PapSmear',
        'Birth',
        'ObservationParameters',
        'LinXmartPPRLs'
    ]
    async function onPIPQUIStatusSaveHandler() {
        setIsRefreshing(true)
        const authHeader = await getAuthHeader(instance, accounts)
        await axios.put(
            `${REACT_APP_PS_PORTAL_API_URI}/practices/${practice.id}/pipqiStatusId/${pipqiStatus}`,
            null,
            { headers: authHeader }
        )
        setShowToastMsg({ visible: true, msg: 'PIP QI Status saved successfully' })
        setIsRefreshing(false)
    }

    async function fetchData() {
        setIsRefreshing(true)

        const authHeader = await getAuthHeader(instance, accounts)

        const httpConfig = {
            headers: authHeader
        }

        const result = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/practices/${params.practiceId}`, httpConfig)

        const data = result.data
        const practiceDetails = {
            id: data.id,
            practiceName: _.get(data, 'name'),
            abbreviation: _.get(data, 'abbreviation'),
            practiceSoftware: _.get(data, 'software.name'),
            practiceGroup: _.get(data, 'group.name'),
            postCode: _.get(data, 'postcode.code'),
            phnName: _.get(data, 'phn.name'),
            pipqiStatus: _.get(data, 'pipqiStatus.description'),
            pipqiStatusId: _.get(data, 'pipqiStatus.id'),
            firstRun: data.firstRun,
            firstRunCompleted: data.firstRunCompleted,
            stopped: data.stopped,
            stoppedDate: data.stoppedDate,
            appClientId: data.appClientId,
            secretExpiry: data.secretExpiry ? moment(data.secretExpiry).format('DD/MM/yyyy') : 'N/A',
            remindSecretExpiry: data.secretExpiry ? moment(data.secretExpiry).diff(moment(), 'days') <= 90 : false,
            daysLeft: data.secretExpiry ? moment(data.secretExpiry).diff(moment(), 'days') : 0,
            settings: data.settings,
        }

        const exResult = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/practices/${params.practiceId}/extraction`, httpConfig)

        const refResult = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/practices/refdata`, httpConfig)

        setPipQIStatus(practiceDetails.pipqiStatusId)
        setRefData(refResult.data)
        setExtractionDetails(exResult.data)

        setPractice(practiceDetails)
        setIsRefreshing(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    function copyToClipboard(value) {
        navigator.clipboard.writeText(value)
        setShowToastMsg({ visible: true, msg: 'Copied' })
    }

    async function reissueIdSecret(setIsSubmitting) {
        setIsSubmitting(true)
        const authHeader = await getAuthHeader(instance, accounts)
        await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/practices/${params.practiceId}/reissueIdSecret`, null, { headers: authHeader })
        setShowConfirmReissueIdSecretDialog(false)
        setShowToastMsg({ visible: true, msg: 'ID/secret has been emailed to IT Provider' })
    }

    async function remindSecretExpiry() {
        setIsSendingRemindEmail(true)
        const authHeader = await getAuthHeader(instance, accounts)
        await axios.post(`${REACT_APP_PS_PORTAL_API_URI}/practices/${params.practiceId}/notifySecretExpiry`, null, { headers: authHeader })
        setShowToastMsg({ visible: true, msg: 'Secret expiry notification has been sent' })
        setIsSendingRemindEmail(false)
    }
    
    return (
        <>
            <ToastContainer className="p-3" position="bottom-center">
                <Toast onClose={() => setShowToastMsg({ visible: false, msg: '' })} show={showToastMsg.visible} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Primary Sense</strong>
                    </Toast.Header>
                    <Toast.Body>{showToastMsg.msg}</Toast.Body>
                </Toast>
            </ToastContainer>
            {!practice.id && <Spinner animation="border" variant="secondary" />}

            {practice.id &&
                <Tabs
                    defaultActiveKey="details"
                    id="practiceSettings"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="details" title="Practice Details">
                        <Container>
                            <Row>
                                <Col>
                                    <p>Name: <b>{practice.practiceName}</b></p>
                                    <p>
                                        Abbreviation: <b>{practice.abbreviation}</b>
                                        <br />
                                        <i>Please use this field to enter the PHN's Internal reference ID for the Practice, which could be helpful to link Practice records external to Primary Sense</i>
                                    </p>
                                    <p>Software: <b>{practice.practiceSoftware}</b></p>

                                    <div className="row mb-3">
                                        <div className="col">
                                            <select value={pipqiStatus} onChange={onPIPQUIStatusChangeHandler} className={'form-control form-select'}>
                                                <option value=""></option>
                                                {
                                                    refData.pipqiStatus && refData.pipqiStatus.map(s => (
                                                        <option key={s.id} value={s.id}>{s.description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className="col">
                                            <Button type="button" size="sm" variant="outline-primary" onClick={() => onPIPQUIStatusSaveHandler()} disabled={isRefreshing || pipqiStatus == ''}>
                                                Save Status {isRefreshing && <span className="spinner-border spinner-border-sm ml-2"></span>}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {practice.appClientId &&
                                <>
                                    <Row>
                                        <Col>
                                            {
                                                practice.remindSecretExpiry === true &&
                                                <Card className="mt-4" style={{ backgroundColor: 'yellow'}}>
                                                    <Card.Body>
                                                        <Card.Title>Secret Expiry</Card.Title>
                                                        <Card.Text>
                                                            <b>{practice.secretExpiry}</b>
                                                            <p className='text-danger'><b>The secret is only valid for {practice.daysLeft} days</b></p>
                                                        </Card.Text>
                                                        <Button type="button" size="sm" variant="primary" onClick={() => remindSecretExpiry()} disabled={isSendingRemindEmail}>
                                                        Remind Secret Expiry {isSendingRemindEmail && <span className="spinner-border spinner-border-sm ml-2"></span>}
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            }
                                            {
                                                practice.remindSecretExpiry  === false &&
                                                <Card className="mt-4">
                                                    <Card.Body>
                                                        <Card.Title>Secret Expiry</Card.Title>
                                                        <Card.Text>
                                                            <b>{practice.secretExpiry}</b>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <Card className="mt-4">
                                                <Card.Body>
                                                    <Card.Title>App Client ID</Card.Title>
                                                    <Card.Text>
                                                        {practice.appClientId}

                                                        <Button className="ms-1" onClick={() => copyToClipboard(practice.appClientId)} variant="outline-primary" size="sm">{<IoClipboardOutline />}</Button>
                                                        <Button disabled={!ctx.isAdmin} onClick={() => setShowConfirmReissueIdSecretDialog(true)} variant="primary" size="sm" className="ms-4">Re-issue Client ID / Secret</Button>
                                                    
                                                    { showConfirmReissueIdSecretDialog &&
                                                        <ConfirmDialog
                                                            title="Confirm"
                                                            body="Reissuing ID/Secret will remove the existing secrets and generate new secret, do you want to continue?"
                                                            onconfirm={reissueIdSecret}
                                                            onhide={() => setShowConfirmReissueIdSecretDialog(false)}
                                                        />
                                                    }
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            }
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>Extraction</Card.Title>
                                            <Card.Text>
                                                First Run: {practice.firstRun ? 'Yes' : 'Done'}
                                                <br />
                                                First Run Completed Date: {practice.firstRunCompleted ? moment(practice.firstRunCompleted).format('DD/MM/yyyy') : 'N/A'}
                                                <br />
                                            </Card.Text>
                                            {extractionDetails.recordCounts.length > 0 &&
                                                <>
                                                    <Button type="button" size="sm" variant="outline-primary" onClick={() => fetchData()} disabled={isRefreshing}>
                                                        Refresh {isRefreshing && <span className="spinner-border spinner-border-sm ml-2"></span>}
                                                    </Button>
                                                    <Table bordered striped className="mt-4" size="sm">
                                                        <thead>
                                                            <tr>
                                                                <th>Table ID</th>
                                                                <th>Table Name</th>
                                                                <th>Initial Record Count</th>
                                                                <th>Current Record Count</th>
                                                                <th>Last ID</th>
                                                                <th>Updated Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {extractionDetails.recordCounts.map((r) => (
                                                                <tr key={r.id}>
                                                                    <td>{r.extractionTableID}</td>
                                                                    <td>{EXTRACTION_TABLE[r.extractionTableID - 1]}</td>
                                                                    <td>{r.initialCount}</td>
                                                                    <td>{r.currentCount}</td>
                                                                    <td>{r.lastID}</td>
                                                                    <td>{r.updatedDate ? moment(r.updatedDate).format('DD/MM/yyyy HH:mm:ss') : ''}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card className="mt-4">
                                        <Card.Body>
                                            <Card.Title>ACG Complexity Scores</Card.Title>
                                            <Card.Text>
                                                Total Patients with ACG Complexity Scores: {extractionDetails.totalACGPatients}
                                                <br />
                                                Total Patients: {extractionDetails.totalPatients}
                                                <br />
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card className="mt-4">
                                        <Card.Body>
                                            <Card.Title>Desktop Details</Card.Title>
                                            <Card.Text>
                                                Total PC's deployed with Desktop Application: {extractionDetails.totalDesktops}
                                                <br />
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card className="mt-4">
                                        <Card.Body>
                                            <Card.Title>Service Details</Card.Title>
                                            <Card.Text>
                                                Practice Server Software Version: {extractionDetails.practiceSoftwareVersion}
                                                <br />
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </Tab>
                    <Tab eventKey="settings" title="Settings">
                        <PracticeSettings practice={practice} />
                    </Tab>
                </Tabs>
            }
        </>
    )
}

export default Practice
